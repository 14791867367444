import React, { FunctionComponent } from 'react'

import { Flex, Grid, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { StateHandler } from 'components/StateHandler'
import { Visualization } from 'components/visualization/Visualization'
import { useAppSelector } from 'store/hooks'
import { useGetReportByAreaQuery } from 'store/reports/reportsApiWithQuery'
import { State } from 'store/state'
import BorderBox from 'ui-kit/BorderBox'

import { useRepresentation } from '../hooks/useRepresentation'

import { ScoreHeader } from './ScoreHeader'

interface ReportsFairnessProps {
  code: string
}

export const ReportsFairness: FunctionComponent<ReportsFairnessProps> = ({
  code,
}) => {
  const { t } = useTranslation()

  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const {
    data: fairnessData,
    isLoading,
    isError,
    isSuccess,
  } = useGetReportByAreaQuery({
    areaCode: code,
    projection: 'fairness',
    apiVersion,
    sessionId,
  })
  const fairness = fairnessData?.fairness?.[0] ?? {}

  const getRepresentation = useRepresentation(code)

  if (!fairness?.area_code?.includes('outcomes')) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  const score = fairness?.score_calculation
    ? // eslint-disable-next-line no-unsafe-optional-chaining
      ((fairness?.score_calculation ?? 0) * 10) / fairness?.area_max
    : 0

  return (
    <StateHandler
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      errorMessage={t('error.TryAgain')}
    >
      <ScoreHeader
        title={t(`reports.fairness.${code}.title`)}
        description={
          code?.endsWith('hiring')
            ? t(`reports.fairness.${code}.description`)
            : undefined
        }
        score={score}
        icon="branches"
      />
      <Grid columns={2} gap={4} mb={9}>
        {getRepresentation().map(({ title, items, benchmark, key }) => (
          <BorderBox sx={{ py: 5 }} key={key}>
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 5,
              }}
            >
              <Text as="div" variant="bodyBold">
                {title}
              </Text>
            </Flex>
            <Visualization
              type="Bar"
              items={items as any}
              benchmark={benchmark as any}
              isLegendVisible
            />
          </BorderBox>
        ))}
      </Grid>
    </StateHandler>
  )
}
